
import { Component, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import NotAllowedComponent from '@/components/views/settings/NotAllowedComponent.vue';
import ProductVersionsTable from '@/components/views/CmsIndex/ProductVersionsTable.vue';
import { ProductVersionTabs } from '@/enums/global/ProductVersionTabs';
import { tableConfig } from '@/helpers/CmsIndexHelper';
import { debounce } from 'vue-debounce';
import { downloadExcelFile } from '@/helpers/ProductVersionHelper';
import ProductImages from '@/components/views/CmsIndex/ProductImages.vue';

@Component({
    name: 'ProductVersions',
    components: {
        SettingsTopBar,
        NotAllowedComponent,
        ProductVersionsTable,
        ProductImages,
    },
})
export default class ProductVersions extends mixins<UserRightsMixin>(UserRightsMixin) {
    private backRoute: RouteNames = RouteNames.settings;
    private isSearchBarLoading = false;
    private tableConfig = tableConfig;
    private activeKey = ProductVersionTabs.PRODUCT_VERSIONS;
    // private activeKey = ProductVersionTabs.PRODUCT_MATERIALS;
    private searchText = '';
    private filteredData: any = [];
    private debouncedSearch = debounce(() => {
        this.searchTable();
    }, 1000);
    private selectedEntity = null;
    private isImagesModalVisible = false;

    private get userHasRights() {
        return this.isSuperAdmin;
    }

    private get searchPlaceholder() {
        switch (this.activeKey) {
            case ProductVersionTabs.PRODUCT_VERSIONS:
                return this.$t('Search product versions by any field');
            case ProductVersionTabs.PRODUCT_MATERIALS:
                return this.$t('Search product materials by any field');
            default:
                return this.$t('Search product functions by any field');
        }
    }

    private get productTableConfig() {
        return this.tableConfig.filter((config: any) => config.entityName !== 'Transferred orders');
    }

    private onTabChange() {
        this.searchText = '';
        this.filteredData = [];
    }

    private onRowClick(row: any) {
        this.selectedEntity = row;
        this.isImagesModalVisible = true;
    }

    private closeImagesModal() {
        this.isImagesModalVisible = false;
    }

    private async searchTable() {
        const tableData: any = this.tableConfig
            ?.find((config: any) => config.entityName === this.activeKey)
            ?.tableData();

        if (!tableData) {
            return;
        }

        const filteredTableData = tableData.filter((row: any) => {
            switch (this.activeKey) {
                case ProductVersionTabs.PRODUCT_VERSIONS:
                    return this.filterProductRow(row);
                default:
                    return this.filterRow(row);
            }
        });

        if (!filteredTableData.length) {
            this.$notification.info({
                message: this.$t('No results found'),
                description: this.$t('Try searching for something else'),
            });
        } else {
            this.filteredData = filteredTableData;
        }
    }

    private filterProductRow(row: any) {
        return Object.entries(row).some(([key, value]: [string, any]) => {
            if (key !== 'key' && (typeof value === 'string' || typeof value === 'number')) {
                return this.isTextMatching(value, this.searchText);
            }

            return Object.values(value).some((nestedValue: any) => {
                if (typeof nestedValue === 'string' || typeof nestedValue === 'number') {
                    return this.isTextMatching(nestedValue, this.searchText);
                }

                return false;
            });
        });
    }

    private filterRow(row: any) {
        return Object.entries(row).some(([key, value]: [string, any]) => {
            if (key !== 'key' && (typeof value === 'string' || typeof value === 'number')) {
                return this.isTextMatching(value, this.searchText);
            }

            return false;
        });
    }

    private downloadExcel() {
        const data = this.tableConfig.find((config: any) => config.entityName === this.activeKey)?.tableData();

        if (!data) {
            this.$notification.error({
                message: this.$t('Error') as string,
                description: this.$t('Could not find data, please try again') as string,
            });

            return;
        }

        this.$notification.info({
            message: this.$t('Downloading Excel file') as string,
            description: this.$t('Please wait while the file is being prepared') as string,
            duration: 3,
        });

        this.downloadFile(data);
    }

    private downloadFile(data: any) {
        try {
            downloadExcelFile(data as any[], this.activeKey, ['key']);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error') as string,
                description: this.$t('An error occurred while downloading the Excel file') as string,
            });
        }
    }

    private isTextMatching(value: string | number, searchText: string) {
        return String(value).toLowerCase().includes(searchText.toLowerCase());
    }

    @Watch('searchText')
    private onSearchTextChange(val: string) {
        if (!val) {
            this.filteredData = [];
            return;
        }

        this.debouncedSearch();
    }
}
